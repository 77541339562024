// import { env } from 'md-base-tools/env'
import { userCenterMaterial } from '@/api';
export default {
  data() {
    return {
      loading: true,
      user_id: '',
      isNone: true,
      list: [{
        file_url: 'https://p6.moimg.net/path/dst_project/202204/1516/5005/2204155005XAqGWNOex9LYzDBo01E4760kj5naZl.png',
        file_name: '暂无数据'
      }]
    };
  },
  async beforeMount() {
    document.title = '经营资质';
    const userId = this.$route.params.id ? this.$route.params.id.replace('.html', '') : '';
    console.log('userId', userId);
    if (userId && Number(userId) && Number(userId) > 0) {
      const {
        status,
        data
      } = await userCenterMaterial({
        user_id: userId
      });
      if (status === 0 && data.list && data.list.length > 0) {
        this.list.splice(0, this.list.length);
        this.list.push(...data.list);
        this.isNone = false;
      }
      this.loading = false;
    } else {
      this.loading = false;
    }
  },
  methods: {}
};